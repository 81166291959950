<template>
  <BaseDialog
    :open="isOpen"
    top="5vh"
    width="532px"
    :close-on-click-modal="true"
    :close-on-press-escape="true"
    :show-close="true"

    @dismiss="close"
  >
    <template #header>
      <h4 class="tw-text-primary-500 tw-flex tw-mb-0 tw-pt-4">
        Nahlásit chybu aplikace
      </h4>
      <p class="tw-text-center tw-text-sm tw-font-normal tw-text-text-light tw-mt-0.5">
        Hlášení chyb slouží k odhalení a opravě chyb a vylepšení aplikace.
      </p>
    </template>
    <template #content>
      <div>
        <el-form
          ref="form"
          :model="form"
          :rules="formRules"
          label-position="top"
          hide-required-asterisk
        >
          <el-form-item
            prop="description"
            label="Popis chyby"
          >
            <el-input
              v-model="form.description"
              type="textarea"
              :autosize="{ minRows: 3, maxRows: 6 }"
              placeholder="Popište problém..."
            />
          </el-form-item>

          <el-form-item
            prop="client"
            label="Klient"
          >
            <el-select
              v-model="form.client"
              size="small"
              placeholder="Vyhledejte klienta..."
              filterable
              remote
              :remote-method="searchClients"
              class="tw-w-full"
              :loading="isClientsLoading"
            >
              <el-option
                v-for="client in filteredClients"
                :key="client.id"
                :label="`${client.first_name} ${client.last_name}`"
                :value="client.id"
              />
            </el-select>
          </el-form-item>

          <el-form-item
            prop="created_at"
            label="Datum"
          >
            <el-date-picker
              v-model="form.date"
              type="datetime"
              placeholder="Datum a čas"
              format="DD. MM. YYYY HH:mm"
              size="small"
              class="tw-w-full"
              :clearable="false"
            />
          </el-form-item>
        </el-form>
      </div>
    </template>
    <template #footer>
      <div class="tw-flex tw-justify-end tw-px-6">
        <el-button
          type="primary"
          size="large"
          :loading="isLoading"
          @click="submitForm"
        >
          Nahlásit
        </el-button>
      </div>
    </template>
  </BaseDialog>
</template>

<script>
const FORM_BASE = {
  description: '',
  client: null,
  operator: null,
  date: null,
  request: null,
  current_url: null,
  replay: null,
};

export default {
  emits: ['closed'],

  data() {
    return {
      request: null,
      isOpen: false,
      isLoading: false,
      isClientsLoading: false,
      form: _cloneDeep(FORM_BASE),
      formRules: {
        description: [
          { required: true, message: 'Prosím zadejte popis chyby', trigger: 'blur' },
        ],
      },
      filteredClients: [],
    };
  },

  computed: {
    openedRequest() {
      return this.$route.params.id ? this.$store.state.operator.requests?.request : null;
    },
  },

  methods: {
    open() {
      this.isOpen = true;
      this.form.operator = useAuthStore().user?.id;
      this.form.client = this.openedRequest?.client?.id;
      this.form.date = dayjs().format();
      this.searchClients(`${this.openedRequest?.client?.first_name} ${this.openedRequest?.client?.last_name}`);

      this.form.request = this.openedRequest?.id;
      this.form.current_url = this.$route.fullPath;

      if (useNuxtApp().$sentry) {
        this.form.replay = window.getReplayURL?.();
      }
    },
    close() {
      this.isOpen = false;
      this.$emit('closed');

      this.form = _cloneDeep(FORM_BASE);
    },
    async searchClients(query) {
      this.isClientsLoading = true;

      const response = await useApi.get('/operator/users', {
        params: {
          limit: 100,
          page: 1,
          q: query,
          staff: 0,
          type: 1,
        },
      }).catch((error) => {
        console.error(error);
      });

      if (response) {
        this.filteredClients = response.results;
      }

      this.isClientsLoading = false;
    },
    async submitForm() {
      const isValid = await isFormValid(this.$refs.form);

      if (!isValid) {
        return;
      }

      this.isLoading = true;

      try {
        await useApi.post('/operator/bug-report', { body: this.form });
        ElMessage.success('Chyba byla úspěšně nahlášena.');
      } catch (error) {
        ElMessage.error('Chybu se nepodařilo nahlásit.');
        console.error(error);
      } finally {
        this.close();
      }

      this.isLoading = false;
    },
  },
};
</script>
