<template>
  <Html :lang="$i18n.locale">
    <Body id="app">
      <el-config-provider :locale="elLocale">
        <NuxtLoadingIndicator
          color="#f9f7f7"
          :height="1"
        />
        <NuxtLayout>
          <NuxtPage />
        </NuxtLayout>
      </el-config-provider>
    </Body>
  </Html>
</template>

<script setup>
import ua from 'element-plus/dist/locale/uk.mjs';
import cs from 'element-plus/dist/locale/cs.mjs';

const i18n = useNuxtApp().$i18n;

// Computed
const elLocale = computed(() => {
  if (i18n.locale.value === 'ua') {
    return _merge(ua, {});
  }
  return _merge(cs, {
    el: {
      datepicker: {
        year: '',
      },
    },
  });
});

useHead({
  titleTemplate: (titleChunk) => titleChunk ? `${titleChunk} | Tichá linka` : 'Tichá linka',
});

// Watchers
watch(i18n.locale, () => {
  dayjs.locale(i18n.locale.value === 'ua' ? 'uk' : 'cs');
  useNuxtApp()?.$sentry?.setTag?.('locale', i18n.locale.value);
}, { immediate: true });

// Watchers - Loggers
watch(() => useAuthStore().user, (user) => {
  if (!user) return;

  if (useNuxtApp().$sentry) {
    useNuxtApp()?.$sentry?.setUser({
      id: user?.id,
      email: user?.email,
      username: user.name ? user.name : `${user.first_name} ${user.last_name}`,
    });

    useNuxtApp()?.$sentry?.setTag?.('user.is_operator', user?.is_operator);
    useNuxtApp()?.$sentry?.setTag?.('user.type', user?.type);
    useNuxtApp()?.$sentry?.setTag?.('buildDate', useRuntimeConfig().public.buildDate);

    window.getReplayURL = () => `https://tichysvet.sentry.io/replays/${useNuxtApp().$replay?.getReplayId?.()}`;
  }
}, { immediate: true });

// Watchers - Layout reset
watch(() => useRoute().path, () => {
  useUIStore().burgerMenu = false;
}, { immediate: true });

// Feature - isNetworkOnlineWatcher
const isOnline = useOnline();
const notifyBox = ref(null);
const audio = ref(new Audio('/sounds/operator_offline.mp3'));

watch(isOnline, async() => {
  if (!isOnline.value) {
    if (!document.title.includes('🔴')) {
      document.title = `🔴 ${document.title}`;
    }

    notifyBox.value = ElNotification.error({
      title: 'Nejste připojeni k internetu',
      duration: 0,
      offset: 80,
      position: 'top-right',
      showClose: false,
      customClass: 'el-notification--danger',
      onClose: () => {
        if (document.title.includes('🔴')) {
          document.title = document.title.replace('🔴 ', '');
        }
      },
    });

    if (useAuthStore()?.user?.is_operator) {
      const isPlayedByExtension = await useUIStore().chromeExtensionSendMessage({ playAudio: 'operator_offline' });
      if (!isPlayedByExtension) {
        if (audio.value) {
          audio.value.loop = true;
          audio.value.play().catch((error) => {
            console.warn(error);
          });
        }
      }
    }
  } else if (notifyBox.value) {
    notifyBox.value.close();

    const isPlayedByExtension = await useUIStore().chromeExtensionSendMessage({ stopAudio: 'operator_offline' });
    if (!isPlayedByExtension) {
      if (audio.value && !audio.value.paused) {
        audio.value.pause();
      }
    }
  }
}, { immediate: true });
</script>
